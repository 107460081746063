@import '../shared/css/colors.scss';
@import '../shared/css/variables.scss';

.coloredIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 9px;
}

.circle {
    width: 9px;
    height: 9px;
    border-radius: 50%;
}


.badge {
    width: 47px;
    height: 30px;
    font-size: $font-16;
    font-weight: 500;
    background-color: $primary-main;
    color: $white-main;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statsBadge {
    width: 30px;
    height: 9px;
    border-radius: 50px;
}

.tableContainer {
    display: flex !important;
    flex-direction: column !important;
    gap: 0.7rem !important;
    height: 100%;
}


.tabContainer {
    background-color: $white-main;
    border-radius: 50px;
    display: inline-block;
}

.selectedTab {
    background-color: $primary-main !important;
    border-radius: 50px !important;
    color: $white-main !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 0 10px !important;
    min-height: 41px !important;
    font-weight: 500 !important;
    font-size: $font-16 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-14 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-18 !important;
    }
}

.tab {
    color: $text-primary !important;
    font-weight: 500 !important;
    font-size: $font-16 !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 0 10px !important;
    min-height: 41px !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-14 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-18 !important;
    }
}

.indicator {
    background-color: transparent !important;
}