@import "../css/colors.scss";

.banner {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    width: 100%;
}

.alert {
    border-radius: 0;
    padding: 12px 20px;
    font-size: 14px;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100%;
    color: white;

    &.error {
        background-color: $error-main !important; // Softer red for errors
    }

    &.warning {
        background-color: $secondary-main !important; // Yellow for warnings
    }

    p {}
}

.link {
    color: $primary-main;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
}

.closeButton {
    margin-left: 10px;
}