@import '../../shared/css/colors.scss';

.pricingContainer {
    display: flex;
    flex-direction: column;
}

.pricingNavContainer {
    height: 12vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
}

.subscriptionDetailCard {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px !important;

    .memberSince {
        // width: 400px;
        text-align: center;
        padding: 5px 15px;
        border-radius: 10px;
        background-color: $info-main;
        color: $white-dark;
    }

    .nextPaymentContainer {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;

        .paymentInfo {
            color: $info-main !important;
            font-weight: bold;
        }

        .buttonContainer {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

    }
}

//payment info

.paymentContent {
    display: flex;
    flex-direction: column;
}

.cardInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cardBrandIcon {
    width: 40px;
    height: auto;
}

.cardNumber {
    font-weight: bold;
    letter-spacing: 2px;
}