.cardContainer {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0 auto;
}

.cardBanner {
  height: 150px;
  background: linear-gradient(to right, #001f3f, #007bff);
}

.cardContent {
  padding: 20px;
}

.profilePicContainer {
  position: relative;
  display: inline-block;
  margin-top: -50px;
}

.profilePic {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  border: 5px solid #fff;
}

.loadingIndicator {
  position: absolute;
  top: 30%;
  left: 30%;
}

.editButton {
  position: absolute !important;
  bottom: 0;
  right: 0;
  background: #fff !important;
  border-radius: 50%;
  padding: 3px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.name {
  margin-top: 10px;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.type {
  color: #0C5E9B !important;
}

.accountType {
  margin-top: 5px;
  color: #9A9AA9 !important;
}

.accountTypeSpan {
  color: #007bff;
}