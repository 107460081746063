@import "../../../shared/css/colors.scss";
@import "../../../shared/css/variables.scss";
//pricingCard

.priceCard {
    width: 100% !important;
    height: 500px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
    padding: 15px !important;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;

    &:hover {
        transform: scale(1.02) !important;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2) !important;
    }
}

.priceCardTitle {
    font-size: 1rem !important;
    font-weight: bold !important;
    margin-bottom: 5px !important;
}

.priceCardPrice {
    font-size: 1.5rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold !important;
    color: $primary-main !important;

    .priceCardCurrency {
        font-size: 1.3rem !important;
    }

    .priceCardDuration {
        font-size: 1rem !important;
        color: #555 !important;
    }
}


.priceCardFeaturesHeading {
    font-weight: bold !important;
}


.priceCardFeatureItem {
    display: flex;
    align-items: center;

    .priceCardFeatureIcon {
        color: $primary-main !important;
        margin-right: 10px;
    }

    p {
        font-size: 0.8rem !important;
        color: #555;
    }
}