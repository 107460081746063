.main {
    max-height: 90vh;
    margin-top: 2rem;
    max-width: 100%;
}

.noSideBar {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .navbar {
        display: flex;
        justify-content: space-between;
    }
}