@import "../../shared/css/colors.scss";
@import "../../shared/css/variables.scss";

.pricingContainer {
    display: flex;
    flex-direction: column;
}

.pricingNavContainer {
    height: 12vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
}

.navigationButton {
    width: 100%;
    margin-bottom: 10px;
}

.pricingBodyContainer {
    min-height: 88vh;
    padding: 0 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    // align-items: center;
}

.pricingBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.mainTitle {
    font-weight: 600 !important;
    font-size: $font-26 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-22 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-20 !important;
    }

}

.pricingCardsContainer {
    display: flex;
    justify-content: space-between;
}


//pricingCard

.priceCard {
    width: 100% !important;
    height: 400px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
    padding: 15px !important;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;

    &:hover {
        transform: scale(1.02) !important;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2) !important;
    }
}

.priceCardTitle {
    font-size: 1rem !important;
    font-weight: bold !important;
    margin-bottom: 5px !important;
}

.priceCardPrice {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    color: $primary-main !important;
    margin-bottom: 5px;

    .priceCardCurrency {
        font-size: 1.3rem !important;
    }

    .priceCardDuration {
        font-size: 1rem !important;
        color: #555 !important;
    }
}

.priceCardFeaturesHeading {
    font-weight: bold !important;
}



.discountMessage {
    font-size: 0.9rem !important;
    color: $success-main !important;
    font-weight: bold;
    margin-top: 5px !important;
}

.lineThrough {
    text-decoration: line-through !important;
    margin-right: 10px !important;
    color: $gray-main !important;
}

.priceCardFeatureItem {
    display: flex;
    align-items: center;

    .priceCardFeatureIcon {
        color: $primary-main !important;
        margin-right: 10px;
    }

    p {
        font-size: 0.8rem !important;
        color: #555;
    }
}

//billing Body

.subscriptionPlanCard {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 10px !important;

    .innerCard {
        min-height: 80px;
        // border: 1px solid $info-main;
        background-color: $white-dark;
        border-radius: 6px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        // align-items: center;
    }

    .flexContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .trialBox {
        width: 150px;
        text-align: center;
        padding: 5px 15px;
        border-radius: 10px;
        background-color: $info-main;
        color: $white-dark;
    }

    .pricingInfo {
        color: $info-main !important;
        font-weight: bold;
    }


}

.paymentFormContainer {

    // PaymentForm.module.scss
    .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .paymentInput {
        padding: 12px;
        background-color: #f0f0f0;
        display: flex;
        flex-direction: column;
        gap: 5px;
        border-radius: 6px;
        margin-bottom: 20px;
    }



    .payBtn {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        background: #0a66c2 !important;
        color: white !important;
        border-radius: 6px;
    }

    .payBtn:disabled {
        background: #9cb6d2 !important;
    }

    .message {
        margin-top: 10px;
        font-weight: bold;
        color: green;
    }
}