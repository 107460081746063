.walkthroughContainer {
    text-align: center;
    padding: 0 !important;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.video {
    border-radius: 8px;
    border: 1px solid #ddd;
}